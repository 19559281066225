<template>
	<div class="user-enroll-shaky">
	<w-navTab titleText="已报名活动"></w-navTab>
		<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="nav"><w-tab :active="active" :data="loveData"></w-tab></div>
			<div class="list-shaky" v-for="(item,index) in shakyList">
				<div class="list-shaky-left">
					<div class="list-title">{{ item.title }}</div>
					<div class="list-bottom">
						<div class="list-bottom-right">{{ item.verify_status }}</div>
						<div class="list-bottom-left">
							<div class="left-picture"><img src="@/assets/img/test246.png" alt="" /></div>
							<div class="left-time">{{ item.create_time }}</div>
						</div>
					</div>
				</div>
				<div class="list-shaky-right">
					<div class="right-btn" @click="onCancel(index)" v-if="item.activity_status < 3">
						取消报名
					</div>
					<div class="right-btn-color" v-else>
						取消报名
					</div>
				</div>
			</div>
		</VantList>
	</div>
</template>

<script>
import VantVendor from '@/vendor/vant';
import WTab from '@/components/Tab';
import VantList from '@/components/VantList';

import SignIn from '@/api/signIn';

export default {
	name: 'UserEnrollShaky',
	data() {
		return {
			loading: true,
			finished: false,
			isEmpty: false,
			active: 0,
			loveData: [
				{
					title: '已报名活动'
				}
			],
			page: 1,
			limit: 10,
			shakyList: []
		};
	},
	created() {
		this.onLoad();
	},
	methods: {
		onLoad() {
			this.loading = true;

			let params = {
				page: this.page,
				limit: this.limit
			};
			SignIn.signInList(params)
				.then(result => {
					this.page++;
					this.shakyList = this.shakyList.concat(result.data.list);
					this.loading = false;
					if (this.shakyList.length <= 0) {
						this.isEmpty = true;
					}
					if (this.shakyList.length >= result.data.total) {
						this.finished = true;
					}
				})
				.catch(error => {});
		},
		onCancel(index){
			VantVendor.Dialog.confirm({
			  title: '提示',
			  message: '你确定取消报名吗？'
			})
			  .then(() => {
			    SignIn.signupCancel({activity_id: this.shakyList[index].id})
			    	.then(result => {
						this.shakyList.splice(index,1)
			    	})
			    	.catch(error => {
						VantVendor.Toast(error.msg);
					});
			  })
			  .catch(() => {
			    // on cancel
			  });
			
		}
	},
	components: {
		WTab,
		VantList
	}
};
</script>

<style lang="less" scoped>
.user-enroll-shaky {
	.nav {
		padding-top: 10px;
		padding-left: 10px;
	}
	.list-view {
		padding: 0 10px;
	}
	.list-shaky {
		padding: 15px 10px;
		background: #fff;
		border-radius: 8px;
		margin-top: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		
		.list-shaky-left {
			flex: 1;
			.list-title {
				font-size: 18px;
				line-height: 24px;
				color: #333;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				margin-bottom: 13px;
			}
			.list-bottom {
				display: flex;
				align-items: center;
				.list-bottom-left {
					display: flex;
					align-items: center;
					.left-picture {
						width: 16px;
						height: 16px;
						margin-right: 2px;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					.left-time {
						font-size: 13px;
						line-height: 16px;
						color: #666;
					}
				}

				.list-bottom-right {
					font-size: 13px;
					line-height: 16px;
					color: #f43e3d;
					margin-right: 10px;
				}
			}
		}
		.list-shaky-right {
			padding-left: 10px;
			.right-btn{
				font-size: 14px;
				padding: 8px 22px;
				color: #FFF;
				background: #3377FF;
				border-radius: 100px;
			}
			.right-btn-color{
				font-size: 14px;
				padding: 8px 22px;
				color: #FFF;
				background: #AAA;
				border-radius: 100px;
			}
		}
	}
}
</style>
