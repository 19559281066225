import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 已报名列表
const signInList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/activity/signInList', params)
}

const getRegisterDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/activity/getRegisterDetail', params)
}

const completeList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/activity/completeList', params)
}

// 活动打卡
const beginRegister = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/activity/beginRegister', params)
}

// 结束打卡
const finishRegister = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/activity/finishRegister', params)
}

// 活动打卡列表
const registerList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/activity/registerList', params)
}

// 取消报名
const signupCancel = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/activity/signupCancel', params)
}

export default {
	signInList,
	getRegisterDetail,
	completeList,
	beginRegister,
	finishRegister,
	registerList,
	signupCancel
}
